@keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale(0.5);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .animate-fade-in-left {
    animation: fadeInLeft 1s ease-out;
  }
  
  .animate-zoom-in {
    animation: zoomIn 1s ease-out;
  }
  
  .animate-fade-in-right {
    animation: fadeInRight 1s ease-out;
  }
  